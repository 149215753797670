import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_REQUIREMENT_TEMPLATES } from "../../config/connString";

const updateRequirementTemplate = createAsyncThunk(
  "lab-requirements/update-requirement-template",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.put(
        URL_REQUIREMENT_TEMPLATES + "/" + data.id,
        data
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { updateRequirementTemplate };
