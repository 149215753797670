import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Box, Chip, Divider, Typography as MuiTypography } from "@mui/material";
import {
  Space,
  Collapse,
  Button as BtnAntd,
  Typography as AntdTypography,
  Modal,
} from "antd";
import { useDispatch } from "react-redux";
import { getTestOrderById, getUserInfoById } from "store";
import { useReactToPrint } from "react-to-print";
import HeaderContent from "components/jkt/admin/testOrder/headerContent";
import PatientInformation from "components/jkt/admin/testOrder/patientInformation";
import TestProfileSection from "components/jkt/admin/testOrder/testProfileSection";
import BillingInformation from "components/jkt/admin/testOrder/billingInformation";
import TestOrderToBePrinted from "components/jkt/admin/testOrder/testOrderPrintLayout/testOrderToBePrinted";
import { encryptTestOrder } from "store";

const PatientOrdersDropdown = ({ patientOrders }) => {
  const { Text } = AntdTypography;
  const { Panel } = Collapse;
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [printTestOrder, setPrintTestOrder] = useState(false);
  const [orderCreator, setOrderCreator] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const [encryptedTestOrder, setEncryptedTestOrder] = useState(null);

  useEffect(() => {
    if (printTestOrder && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printTestOrder]);

  useEffect(() => {
    dispatch(
      encryptTestOrder({
        orderId: orderDetails?.id,
      })
    ).then((res) => {
      setEncryptedTestOrder(res?.payload?.data);
    });
  }, [dispatch, orderDetails?.id]);

  const onBeforePrintContent = () => {
    //Before printing the test order summary, it should display all of the data not the paginated one using a state
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(true);
    });
  };

  const onAfterPrintContent = () => {
    //After printing set the state to false, to re display the paginated data
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(false);
    });
  };

  //Function for closing order details modal
  const handleCloseOrderDetailsModal = () => {
    setShowOrderDetailsModal(false);
    setOrderDetails(null);
  };

  const handlePrintTestOrder = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: onBeforePrintContent,
    onAfterPrint: onAfterPrintContent,
  });

  const handleShowOrderDetailsModal = (selectedOrderId) => {
    //Function for showing test order details modal
    setShowOrderDetailsModal(true);
    setIsLoading(true);
    dispatch(getTestOrderById({ id: selectedOrderId })).then((response) => {
      //Fetching of test order details
      const isFetchSuccess = response?.payload?.success;
      if (isFetchSuccess) {
        setOrderDetails(response?.payload?.data);
        dispatch(getUserInfoById(response?.payload?.data?.createdBy)).then(
          (userRes) => {
            const isFetchSuccess = userRes?.payload?.success;
            if (isFetchSuccess) {
              setOrderCreator(userRes?.payload?.data);
              setIsLoading(false);
            }
          }
        );
      }
    });
  };

  return (
    <Box>
      <Divider>
        <Chip label="Test Orders" />
      </Divider>
      <Collapse
        style={{
          marginBottom: "0.5rem",
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        {patientOrders?.map((patientOrder) => (
          <Panel
            style={{ padding: "0 0.5rem" }}
            header={
              <MuiTypography sx={{ fontSize: "0.85rem" }} variant="p">
                Order #{" "}
                <span className="font-bold">
                  {patientOrder?.autoGeneratedOrderNumber}
                </span>
              </MuiTypography>
            }
            key={patientOrder?.id}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Space wrap>
                <p>Order Status:</p>
                <Text code>{patientOrder?.orderStatus}</Text>
              </Space>
              <Space wrap>
                <p>Date And Time Ordered:</p>
                <Text code>
                  {moment(patientOrder?.orderDate).format("LLL")}
                </Text>
              </Space>
              <BtnAntd
                onClick={() => handleShowOrderDetailsModal(patientOrder?.id)}
                size="small"
                className="submitBtn"
                type="primary"
                style={{ marginTop: "0.9rem" }}
              >
                {" "}
                View full details
              </BtnAntd>
            </Box>
          </Panel>
        ))}
      </Collapse>
      <Modal
        onCancel={handleCloseOrderDetailsModal}
        open={showOrderDetailsModal}
        footer={[
          <BtnAntd
            style={{ marginLeft: "1.5rem" }}
            key="submit"
            htmlType="button"
            type="primary"
            className="submitBtn"
            size="medium"
            onClick={handlePrintTestOrder}
          >
            Print
          </BtnAntd>,
        ]}
        width={"60rem"}
        style={{ left: 122 }}
        title={
          <AntdTypography style={{ fontSize: "0.9rem" }}>
            <blockquote>
              ORDER ID: {orderDetails?.id}{" "}
              {orderDetails?.orderNumber &&
                `- PICKUP CODE: ${orderDetails?.orderNumber}`}
            </blockquote>
          </AntdTypography>
        }
      >
        {!printTestOrder && (
          <Box
            sx={{
              flexGrow: 1,
              padding: {
                xs: "0.3rem",
                sm: "0.3rem",
                md: "0.9rem",
                lg: !printTestOrder ? "2rem" : 0,
                xl: !printTestOrder ? "2rem" : 0,
              },
              height: orderDetails?.isMinimalOrder ? "auto" : "30rem",
              overflowY: orderDetails?.isMinimalOrder ? "auto" : "scroll",
            }}
            ref={componentRef}
          >
            <HeaderContent
              orderStatus={orderDetails?.orderStatus}
              orderNumber={orderDetails?.autoGeneratedOrderNumber}
              clientDetails={orderDetails?.client}
              isLoading={isLoading}
              cancelReason={orderDetails?.cancelReason}
              orderDate={orderDetails?.orderDate}
            />
            <PatientInformation
              patientDetails={orderDetails?.patient}
              provider={orderDetails?.provider}
              isLoading={isLoading}
              specimens={orderDetails?.patientSpecimens}
              clientWillDrawSpecimen={orderDetails?.clientWillDrawSpecimen}
            />
            {!orderDetails?.isMinimalOrder && (
              <>
                <TestProfileSection
                  testOrderDetails={orderDetails?.testOrderDetails}
                  diagnosesList={orderDetails?.diagnosesList}
                  isLoading={isLoading}
                  specimens={orderDetails?.patientSpecimens}
                  specialHandlings={orderDetails?.specialHandlings}
                  preparedBy={orderCreator?.displayName}
                />
              </>
            )}

            {orderDetails?.isMinimalOrder && (
              <h1 className="text-center text-3xl mt-10 font-semibold">
                LRF Pickup Request
              </h1>
            )}
            <BillingInformation
              clientDetails={orderDetails?.client}
              isLoading={isLoading}
              courierRemarks={orderDetails?.courierRemarks}
              testOrderNotes={orderDetails?.notes}
            />
          </Box>
        )}
        {printTestOrder && (
          <TestOrderToBePrinted
            clientWillDrawSpecimen={orderDetails?.clientWillDrawSpecimen}
            encryptedTestOrder={encryptedTestOrder}
            orderStatus={orderDetails?.orderStatus}
            orderNumber={orderDetails?.autoGeneratedOrderNumber}
            isLoading={isLoading}
            cancelReason={orderDetails?.cancelReason}
            patientDetails={orderDetails?.patient}
            provider={orderDetails?.provider}
            specimens={orderDetails?.patientSpecimens}
            testOrderDetails={orderDetails?.testOrderDetails}
            diagnosesList={orderDetails?.diagnosesList}
            specialHandlings={orderDetails?.specialHandlings}
            courierRemarks={orderDetails?.courierRemarks}
            preparedBy={orderCreator?.displayName}
            isMinimalOrder={orderDetails?.isMinimalOrder}
            clientDetails={orderDetails?.client}
            printTestOrder={printTestOrder}
            ref={componentRef}
          />
        )}
      </Modal>
    </Box>
  );
};

export default PatientOrdersDropdown;
