export const testOrderStatuses = [
  {
    label: "Ready For Pickup",
    value: "ReadyForPickup",
  },
  {
    label: "In Transit",
    value: "InTransit",
  },
  {
    label: "Completed Delivery",
    value: "CompletedDelivery",
  },
  {
    label: "Picked Up",
    value: "PickedUp",
  },
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Cancelled",
    value: "Canceled",
  },
  {
    label: "Hold",
    value: "Hold",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Missed Pickup",
    value: "MissedPickup",
  },
];
