import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import { theme } from "components/jkt/global/theme";
import MenuBar from "components/jkt/global/menuBar";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import GetAllLabtestRequirementTemplate from "components/jkt/admin/labtestRequirementTemplate/getAllLabtestRequirementTemplate";

const LabTestRequirementTemplatePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Requirement Template"} />
        <TableContentLayout>
          <GetAllLabtestRequirementTemplate />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default LabTestRequirementTemplatePage;
