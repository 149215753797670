import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SUPPLY_ORDER } from "config/connString";
import useAxios from "utils/useAxios";

const getAllSupplyRequestStatuses = createAsyncThunk(
  "supply-orders/get-supply-request-statuses",
  async () => {
    try {
      const api = useAxios();

      const response = await api.get(
        URL_SUPPLY_ORDER + "/supply-request-statuses"
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllSupplyRequestStatuses };
