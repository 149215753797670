import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_REQUIREMENT_TEMPLATES } from "../../config/connString";

const getRequirementTemplateById = createAsyncThunk(
  "lab-requirements/get-requirement-template-by-id",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_REQUIREMENT_TEMPLATES + "/" + data?.id
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { getRequirementTemplateById };
