import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SUPPLY_ORDER } from "config/connString";
import useAxios from "utils/useAxios";

const getAllSupplyRequest = createAsyncThunk(
  "supply-orders/get-all-supply-request",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          pageSize: data.pageSize,
          pageIndex: data.pageIndex,
          isDescending: data.isDescending,
          clientCode: data.clientCode,
          sortField: data.sortField,
          requestId: data.requestId,
          requestStatus: data.requestStatus,
          labIssueId: data.labIssueId,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      };
      const response = await api.get(
        URL_SUPPLY_ORDER + "/supply-requests",
        config
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllSupplyRequest };
