import { Grid, Button, Box } from "@mui/material";
import { getRegisteredQrCodeRanges } from "store/index";
import { useDispatch } from "react-redux";
import { Table, Input, ConfigProvider, Button as BtnAntd } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useDebounce from "hooks/useDebounce";
import { useCurrentHeight } from "utils/getScreenSize";
import { useCallback, useEffect, useState } from "react";
import { EmptyData } from "components/jkt/global/emptyData";
import moment from "moment";
import useVersionCheck from "hooks/useVersionChecker";

const GetQrCodeRegisteredRanges = ({ handleOpenQrModal }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [pageSize, setPageSize] = useState(10);
  const [registeredQrCodeData, setRegisteredQrCodeData] = useState();

  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getRegisteredQrCodeRanges.pending().type ===
      "qr-code/get-registered-ranges/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(getRegisteredQrCodeRanges()).then((element) => {
      if (element.type === "qr-code/get-registered-ranges/fulfilled") {
        setIsLoading(false);
        setRegisteredQrCodeData(element.payload.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  // const handleChangeSearch = (val) => {
  //   setIsLoading(true);
  //   setSearchText(val.target.value);
  //   setCurrent(1);
  // };

  const renderEmptyData = () => (
    <EmptyData
      description="No Qr Code Detected"
      btnTitle="Register Qr Code"
      action={handleOpenQrModal}
    />
  );

  const columns = [
    {
      title: "Qr Code Number",
      key: "qrCodeNumber",
      width: 80,
      render: (record) => {
        const year = record.year;
        const startRange = String(record.startRange).padStart(6, "0");
        const endRange = String(record.endRange).padStart(6, "0");
        return (
          <div className="inline-flex w-full">
            <p className="w-1/4">{`ABC${year}-${startRange}`}</p>
            <span className="w-1/4 text-center">-</span>
            <p className="w-1/4 pl-4">{`ABC${year}-${endRange}`}</p>
          </div>
        );
      },
    },
    {
      title: "Date of Registration",
      dataIndex: "dateCreated",
      key: "dateCreated",
      width: 60,
      render: (text) => moment(text).format("MMMM D, YYYY"),
    },
    {
      title: "Registered By",
      dataIndex: "createdByUserName",
      key: "createdByUserName",
      width: 60,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Qr Code"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "typeName",
                label: "Qr Code",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid> */}

        <Grid item xs={12} md={3} lg={3}>
          <Button
            startIcon={<AddOutlinedIcon />}
            variant="text"
            onClick={handleOpenQrModal}
          >
            CHECK/REGISTER QR CODE
          </Button>
        </Grid>

        {/* <Grid item xs={12} md={3} lg={3}>
          <Box
            display="flex"
            justifyContent={{
              xs: "center",
              md: "flex-start",
            }}
          >
            <Button variant="outlined">EXPORT QR CODE HISTORY</Button>
          </Box>
        </Grid> */}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={registeredQrCodeData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
    </>
  );
};
export default GetQrCodeRegisteredRanges;
