import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getLastRegisteredQrCode } from "store";

const useLastRegisteredQrCode = (setIsLoading) => {
  const dispatch = useDispatch();
  const [lastRegisteredQrCode, setLastRegisteredQrCode] = useState(null);

  const loadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getLastRegisteredQrCode.pending().type ===
      "qr-code/get-last-registered-qrcode/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(getLastRegisteredQrCode()).then((element) => {
      if (element.type === "qr-code/get-last-registered-qrcode/fulfilled") {
        setIsLoading(false);
        setLastRegisteredQrCode(element.payload.data);
      }
    });
  }, [dispatch]);

  return { lastRegisteredQrCode, setLastRegisteredQrCode, loadData };
};

export default useLastRegisteredQrCode;
