import React, { useState } from "react";
import QrRegisterCheckerModal from "./qrRegisterCheckerModal";
import GetQrCodeRegisteredRanges from "./getQrCodeRegisteredRanges";

const QrLayout = () => {
  const [openQrModal, setOpenQrModal] = useState(false);

  const handleCancel = () => {
    setOpenQrModal(false);
  };

  const handleOpenQrModal = () => {
    setOpenQrModal(true);
  };

  return (
    <>
      <GetQrCodeRegisteredRanges handleOpenQrModal={handleOpenQrModal} />
      <QrRegisterCheckerModal
        openQrModal={openQrModal}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default QrLayout;
