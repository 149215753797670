import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_REQUIREMENT_TEMPLATES } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getAllRequirementTemplate = createAsyncThunk(
  "lab-requirements/get-all-requirement-template",
  async (data) => {
    try {
      const api = useAxios();

      const config = {
        params: {
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_REQUIREMENT_TEMPLATES, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getAllRequirementTemplate };
