import { Select } from "antd";
import { testOrderStatuses } from "data/testOrderStatuses";
import React from "react";

const OrderStatusFilter = ({ selectedStatuses, handleSelectStatus }) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      allowClear
      mode="multiple"
      style={{ width: selectedStatuses.length > 0 ? "auto" : 170 }}
      options={testOrderStatuses}
      value={selectedStatuses}
      onChange={handleSelectStatus}
      maxTagCount={1}
      placeholder="Select order status"
      filterOption={filterOption}
      virtual={false}
    />
  );
};

export default OrderStatusFilter;
