export const groupData = (groupKey, dataSet) => {
  const result = Object.groupBy(dataSet, (item) => item[groupKey]);

  const formattedResult = Object.keys(result)?.map((item) => ({
    groupName: item,
    data: result[item],
  }));

  return formattedResult;
};
