// Function for getting lab requirement require value depending on group
export const getLabRequirementRequireValue = (
  labTestRequirements,
  groupName,
  defaultValue
) => {
  const groupIdentifiers = [
    ...new Set(
      labTestRequirements
        ?.filter((item) => item.groupIdentifier !== null)
        ?.map((data) => data.groupIdentifier)
    ),
  ];

  const requireValueChecker = groupIdentifiers.map((groupIdentifier) => {
    const matchedData = labTestRequirements?.filter(
      (item) => item.groupIdentifier === groupIdentifier
    );
    const isGroupRequired =
      matchedData.length > 1 && matchedData.every((data) => data.isRequired);

    return {
      groupName: groupIdentifier,
      allItemInGroupIsRequired: isGroupRequired,
    };
  });

  const matchedRequireValue = requireValueChecker?.find(
    (item) => item.groupName === groupName
  )?.allItemInGroupIsRequired;

  return {
    requireValue: matchedRequireValue ? false : defaultValue,
    requireType: matchedRequireValue ? "OptionalAll" : "Remain",
  };
};
