import { Alert, Button, Input, Popconfirm, Select } from "antd";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import { getInventoryItemsLookup } from "store";
import { clientLookUp } from "store";
import { SyncOutlined } from "@ant-design/icons";
import { parsedClientDetails } from "utils/parsedClientDetails";

const { TextArea } = Input;

const CreateSupplyRequestFormInputs = ({
  supplyRequestFormInputs,
  requestedItemInputs,
  handleChangeSupplyRequestFormInputs,
  handleChangeRequestedItemInputs,
  resetRequestedItemInputs,
  isFormDirty,
  isDataForUpdate,
  setSupplyRequestFormInputs,
  setIsDataForUpdate,
}) => {
  const dispatch = useDispatch();
  const [inventoryItemData, setInventoryItemData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const { isClientAbcLab } = parsedClientDetails();
  const { user } = useSelector((state) => state.auth);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const disableSubmitButton =
    requestedItemInputs.inventoryItemId === null ||
    requestedItemInputs.quantityRequested === null;
  const emptyClientCode =
    isClientAbcLab &&
    isFormDirty &&
    supplyRequestFormInputs.clientCode === null;
  const emptyRequestedBy =
    isClientAbcLab &&
    isFormDirty &&
    (supplyRequestFormInputs.requestedBy === null ||
      supplyRequestFormInputs.requestedBy === "");
  const invalidRequestedQuantity = requestedItemInputs.quantityRequested <= 0;

  const showClearButton =
    requestedItemInputs.inventoryItemId !== null ||
    requestedItemInputs.quantityRequested !== null ||
    requestedItemInputs.purpose !== null;

  const label = (text, isRequired) => {
    return (
      <span
        className={`text-[#324661] font-[500] ${isRequired ? "required" : ""}`}
      >
        {text}:
      </span>
    );
  };

  const handleAddSupplyRequestItem = () => {
    if (!invalidRequestedQuantity) {
      handleChangeSupplyRequestFormInputs("requestedItems", [
        ...supplyRequestFormInputs.requestedItems,
        {
          ...requestedItemInputs,
          prevSelectedInventoryItemId: requestedItemInputs.inventoryItemId,
        },
      ]);
      resetRequestedItemInputs();
    }
  };

  const renderWarningMessage = (emptyInput, description) => {
    if (emptyInput) {
      return <small className="text-[12px] text-red-400">{description}</small>;
    }
  };

  const handleSaveChanges = () => {
    if (!invalidRequestedQuantity) {
      const modifiedSupplyRequestInput =
        supplyRequestFormInputs?.requestedItems?.map((data) =>
          data.inventoryItemId ===
          requestedItemInputs.prevSelectedInventoryItemId
            ? {
                ...requestedItemInputs,
                prevSelectedInventoryItemId:
                  requestedItemInputs.inventoryItemId,
              }
            : data
        );

      setSupplyRequestFormInputs({
        ...supplyRequestFormInputs,
        requestedItems: modifiedSupplyRequestInput,
      });
      handleResetRequestedInputs();
    }
  };

  const handleResetRequestedInputs = () => {
    resetRequestedItemInputs();
    setIsDataForUpdate(false);
  };

  useEffect(() => {
    // For fetching inventory items
    dispatch(getInventoryItemsLookup()).then((res) => {
      const formattedInventoryItemData = res?.payload?.data?.map((item) => {
        const disabledItem = supplyRequestFormInputs?.requestedItems
          ?.filter((requestedItemData) => {
            if (isDataForUpdate) {
              return (
                requestedItemInputs?.prevSelectedInventoryItemId !==
                requestedItemData?.inventoryItemId
              );
            }
            return requestedItemData;
          })
          ?.find((data) => data.inventoryItemId === item.id);
        return {
          label: item.name,
          value: item.id,
          disabled: disabledItem,
        };
      });
      setInventoryItemData(formattedInventoryItemData);
    });

    // For fetching clients
    dispatch(clientLookUp()).then((res) => {
      const formattedClientData = res?.payload?.data?.map((client) => ({
        label: client.displayName,
        value: client.clientCode,
      }));
      setClientsData(formattedClientData);
    });
    // eslint-disable-next-line
  }, [dispatch, supplyRequestFormInputs, isDataForUpdate]);

  return (
    <div className={`${isDataForUpdate ? "mt-7" : "mt-3"} pb-3 space-y-3`}>
      {isDataForUpdate && (
        <Alert
          className="mt-1"
          message={
            <span>
              <SyncOutlined spin /> Updating Data
            </span>
          }
          type="info"
        />
      )}
      {/* First Section */}
      <div className="flex items-start gap-3 w-full">
        <div className="w-full flex flex-col">
          {label("Item", true)}
          <Select
            onChange={(value) =>
              handleChangeRequestedItemInputs("inventoryItemId", value)
            }
            value={requestedItemInputs.inventoryItemId}
            size="large"
            showSearch
            placeholder="Select  inventory item..."
            filterOption={filterOption}
            optionFilterProp="label"
            options={inventoryItemData}
            virtual={false}
          />
        </div>
        <div>
          {label("Item Quantity", true)}
          <div className="flex items-center gap-1">
            <button
              disabled={
                requestedItemInputs.quantityRequested === null ||
                requestedItemInputs.quantityRequested === 1
              }
              onClick={() => {
                handleChangeRequestedItemInputs(
                  "quantityRequested",
                  requestedItemInputs.quantityRequested - 1
                );
              }}
              className="bg-[#7c99d8] text-white px-[10px] py-[6px] rounded-[6px] disabled:bg-[#808ca7] disabled:cursor-not-allowed"
            >
              <RemoveIcon />
            </button>
            <Input
              onChange={(e) =>
                handleChangeRequestedItemInputs(
                  "quantityRequested",
                  isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber
                )
              }
              value={requestedItemInputs.quantityRequested}
              type="number"
              className={`${
                invalidRequestedQuantity
                  ? "border border-red-500 rounded-lg focus:border focus:border-red-500"
                  : ""
              } hide-spin-buttons w-20 text-center`}
              size="large"
            />
            <button
              disabled={requestedItemInputs.quantityRequested === null}
              onClick={() => {
                handleChangeRequestedItemInputs(
                  "quantityRequested",
                  requestedItemInputs.quantityRequested + 1
                );
              }}
              className="bg-[#7c99d8] text-white px-[10px] py-[6px] rounded-[6px] disabled:bg-[#808ca7] disabled:cursor-not-allowed"
            >
              <AddIcon />
            </button>
          </div>
          {renderWarningMessage(invalidRequestedQuantity, "Invalid quantity")}
        </div>
      </div>
      {/* Second Section */}
      <div>
        {label("Purpose")}
        <TextArea
          autoSize={{ minRows: 2.5, maxRows: 3 }}
          onChange={(e) =>
            handleChangeRequestedItemInputs("purpose", e.target.value)
          }
          value={requestedItemInputs.purpose}
        />
      </div>
      {/* Third Section */}
      <div className="flex items-end justify-between gap-3 w-full">
        <div className="flex items-start gap-3">
          {isClientAbcLab && (
            <div className="flex flex-col">
              {label("Client", true)}
              <Select
                className={`w-72 ${
                  emptyClientCode
                    ? "border border-red-500 rounded-lg focus:border focus:border-red-500"
                    : ""
                }`}
                onChange={(value) =>
                  handleChangeSupplyRequestFormInputs("clientCode", value)
                }
                value={supplyRequestFormInputs.clientCode}
                size="large"
                showSearch
                placeholder="Select client..."
                filterOption={filterOption}
                optionFilterProp="label"
                options={clientsData}
                virtual={false}
              />
              {renderWarningMessage(emptyClientCode, "Client is required")}
            </div>
          )}
          <div>
            {label("Requested by", true)}
            <Input
              className={`${
                emptyRequestedBy
                  ? "border border-red-500 rounded-lg focus:border focus:border-red-500"
                  : ""
              }`}
              onChange={(e) =>
                handleChangeSupplyRequestFormInputs(
                  "requestedBy",
                  e.target.value
                )
              }
              disabled={!isClientAbcLab}
              name="requestedBy"
              size="large"
              value={
                !isClientAbcLab
                  ? user?.displayName
                  : supplyRequestFormInputs.requestedBy
              }
            />
            {renderWarningMessage(
              emptyRequestedBy,
              "Please specify who requested this"
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          {showClearButton && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={handleResetRequestedInputs}
            >
              <Button className="text-[#1976D2]" type="text">
                Clear
              </Button>
            </Popconfirm>
          )}
          {!isDataForUpdate ? (
            <Button
              onClick={handleAddSupplyRequestItem}
              className="gap-1 bg-[#1976D2]"
              type="primary"
              disabled={disableSubmitButton}
            >
              Add Item
            </Button>
          ) : (
            <Button
              onClick={handleSaveChanges}
              className="gap-1 bg-[#1976D2]"
              type="primary"
              disabled={disableSubmitButton}
            >
              Save Changes
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSupplyRequestFormInputs;
