import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_REGISTER_QRCODE } from "../../config/connString";

const getLastRegisteredQrCode = createAsyncThunk(
  "qr-code/get-last-registered-qrcode",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(URL_REGISTER_QRCODE + "/last-registered");
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getLastRegisteredQrCode };
