import { getBaseUrl } from "utils/getBaseUrl";

const { REACT_APP_PROJECT_PHASE } = process.env;

const phase = REACT_APP_PROJECT_PHASE;

const isProjectPhaseLive = phase === "LIVE";

const BASE_URL = getBaseUrl();

export { BASE_URL, phase, isProjectPhaseLive };
