import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { typeOptions } from "./data";
import useAdditionalInfoFunctions from "./useAdditionalInfoFunctions";
import useTranslation from "hooks/useTranslation";
import InfoIcon from "@mui/icons-material/Info";
import { Alert } from "antd";
import { getLabRequirementRequireValue } from "components/jkt/testRequest/proxies/getLabRequirementRequireValue";

const ProfilePanelPackageLabReq = ({
  inputValues,
  setInputValues,
  labTestNames,
  fetchedInputFields,
  profilePackagePanelLabRequirements,
  groupsThatHasDirtyInputs,
}) => {
  const { handleInputChange, handleInputCheckboxChange } =
    useAdditionalInfoFunctions();
  const { getTranslation } = useTranslation("RequestPage");

  const showTestNameUniquely = (labtestInfoId, previousLabtestId) => {
    //Function for showing labtest name
    if (labtestInfoId !== previousLabtestId) {
      return true;
    }

    return false;
  };

  const showGroupNameUniquely = (currentGroupName, previousGroupName) => {
    //Function for showing labtest requirement group name
    if (currentGroupName !== previousGroupName) {
      return true;
    }

    return false;
  };

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <Typography
        sx={{
          fontSize: "1rem",
          textAlign: "center",
          paddingTop: "0.5rem",
          color: "grayishBlue.light",
          fontWeight: "bold",
        }}
      >
        {getTranslation("Additional Patient Information")}
      </Typography>
      <Box
        sx={{
          backgroundColor: "#E7EBF4",
          padding: "1rem 0.7rem",
          margin: "0.8rem 1rem",
        }}
      >
        {fetchedInputFields?.map((input, index) => {
          const tooltipTitle = "Please fill atleast one data";

          return (
            <Box key={index}>
              {showTestNameUniquely(
                input.labTestInfoId,
                fetchedInputFields[fetchedInputFields.indexOf(input) - 1]
                  ?.labTestInfoId
              ) && (
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    pb: 3,
                  }}
                >
                  For{" "}
                  {
                    labTestNames?.find(
                      (data) => data.id === input.labTestInfoId
                    )?.name
                  }
                </Typography>
              )}
              {(input.groupIdentifier === null ||
                input.groupIdentifier === "") && (
                <Divider sx={{ marginY: "12px" }} />
              )}
              {input.groupIdentifier !== null &&
                input.groupIdentifier !== "" &&
                showGroupNameUniquely(
                  input.groupIdentifier,
                  fetchedInputFields[fetchedInputFields?.indexOf(input) - 1]
                    ?.groupIdentifier
                ) && (
                  <>
                    <Divider textAlign="center" sx={{ margin: "1rem 0" }}>
                      <Chip
                        label={
                          <div className="flex items-center gap-1">
                            <p>{input?.groupIdentifier}</p>
                            {getLabRequirementRequireValue(
                              profilePackagePanelLabRequirements,
                              input.groupIdentifier,
                              input.isRequired
                            )?.requireType === "OptionalAll" && (
                              <Tooltip
                                arrow
                                title={tooltipTitle}
                                placement="right"
                              >
                                <InfoIcon
                                  sx={{ color: "grayishBlue.light" }}
                                  fontSize="sm"
                                />
                              </Tooltip>
                            )}
                          </div>
                        }
                      />
                    </Divider>
                    {groupsThatHasDirtyInputs.includes(
                      input.groupIdentifier
                    ) && (
                      <Alert
                        className="my-2"
                        message={
                          <p className="text-xs">
                            Please make sure to fill in at least one of the data
                            points.
                          </p>
                        }
                        type="error"
                        showIcon
                      />
                    )}
                  </>
                )}
              {input.requirementType === 3 ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={(e) =>
                          handleInputCheckboxChange(e, index, setInputValues)
                        }
                        checked={
                          inputValues.find((_, inputIdx) => inputIdx === index)
                            ?.value === "true"
                        }
                      />
                    }
                    id={index}
                    label={
                      <Typography
                        className={input.isRequired ? "required" : ""}
                        sx={{
                          color: "grayishBlue.light",
                          fontSize: "1rem",
                        }}
                        variant="p"
                      >
                        {input.requirementDetails}
                      </Typography>
                    }
                  />
                </FormGroup>
              ) : input.requirementType === 4 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "0.2rem",
                  }}
                >
                  <FormControl>
                    <Typography
                      className={input.isRequired ? "required" : ""}
                      sx={{
                        color: "grayishBlue.light",
                        fontSize: "1rem",
                      }}
                      variant="p"
                    >
                      {input.requirementDetails}
                    </Typography>
                    <RadioGroup
                      row
                      value={
                        inputValues?.find((_, inputIdx) => inputIdx === index)
                          ?.value || ""
                      }
                      onChange={(e) =>
                        handleInputChange(e, index, setInputValues)
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : (
                <Box sx={{ margin: "0.5rem 0" }}>
                  <Typography
                    className={input.isRequired ? "required" : ""}
                    sx={{
                      color: "grayishBlue.light",
                      fontSize: "1rem",
                    }}
                    variant="p"
                  >
                    {input.requirementDetails}
                  </Typography>
                  <TextField
                    id={index}
                    size="small"
                    value={
                      inputValues?.find((_, inputIdx) => inputIdx === index)
                        ?.value || ""
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": {
                          border: "1px solid",
                          borderColor: "darkBlue.secondary",
                          borderRadius: "0.7rem",
                        },
                      },
                      marginTop: "0.5rem",
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: "0.7rem",
                    }}
                    required={input.isRequired}
                    type={
                      typeOptions.find(
                        (type) => type.id === input.requirementType
                      )?.type || "text"
                    }
                    onChange={(e) =>
                      handleInputChange(e, index, setInputValues)
                    }
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ProfilePanelPackageLabReq;
