import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SUPPLY_ORDER } from "config/connString";
import useAxios from "utils/useAxios";

const getSupplyRequestAuditTrail = createAsyncThunk(
  "supply-orders/get-supply-request-audit-trail",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.get(
        // eslint-disable-next-line no-useless-concat
        URL_SUPPLY_ORDER +
          "/supply-request-audit-trails" +
          "/" +
          data.requestId,
        {
          requestId: data.requestId,
        }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getSupplyRequestAuditTrail };
