import { Button, Form, Input, Popover, Select } from "antd";
import { requirementTypeOptions } from "./data";
import { useState } from "react";
import { createRequirementTemplate } from "store";
import { useDispatch } from "react-redux";
import { showToastNotification } from "utils/showToastNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";

const CreateLabRequirementPopover = ({
  open,
  onOpenChange,
  refetchData,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (
      createRequirementTemplate.pending().type ===
      "lab-requirements/create-requirement-template/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(createRequirementTemplate(values)).then((res) => {
      const isCreateSuccessful = res?.payload?.success;

      if (isCreateSuccessful) {
        refetchData();
        setIsLoading(false);
        onOpenChange(false);
        showToastNotification(
          "success",
          "Created Data",
          "Successfully created labtest requirement."
        );
      }

      if (!isCreateSuccessful) {
        setIsLoading(false);
        showToastNotification(
          "error",
          "Failed to create labtest requirement",
          errorMessagesDescription(res.payload?.response.data.errorMessages)
        );
      }
    });
  };
  const popoverContent = (
    <Form
      name="labtestRequirement"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      {/* Template Name */}
      <Form.Item
        label="Template Name"
        name="templateName"
        rules={[
          {
            required: true,
            message: <i>{"Please input Template Name."}</i>,
          },
        ]}
      >
        <Input placeholder="Template Name" />
      </Form.Item>
      {/* Requirement Details */}
      <Form.Item
        label="Requirement Details"
        name="requirementDetails"
        rules={[
          {
            required: true,
            message: <i>{"Please input Requirement Details."}</i>,
          },
        ]}
      >
        <Input placeholder="Requirement Details" />
      </Form.Item>
      {/* Requirement Type */}
      <Form.Item
        label="Requirement Type"
        name="requirementType"
        rules={[
          {
            required: true,
            message: <i>{"Please select Requirement Type."}</i>,
          },
        ]}
      >
        <Select
          virtual={false}
          options={requirementTypeOptions}
          placeholder="Requirement Type"
        />
      </Form.Item>

      {/* Submit Button */}
      <Form.Item>
        <Button
          block
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ margin: 2 }}
          className="submitBtn"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      content={popoverContent}
      title="Create Labtest Requirement"
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  );
};

export default CreateLabRequirementPopover;
