import { useState } from "react";
import packageJson from "../../package.json";
import { URL_SYSTEM_CONFIGURATION } from "config/connString";

const APP_VERSION_LOCAL_STORAGE_NAME = "app-version";

const useVersionChecker = () => {
  const appVersion = packageJson.version;
  const currentVersion = localStorage.getItem(APP_VERSION_LOCAL_STORAGE_NAME);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  const handleCheckAppVersion = async () => {
    try {
      const response = await fetch(
        `${URL_SYSTEM_CONFIGURATION}/react-app-version`
      );

      const data = await response.json();
      const version = data.data;

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const isVersionOutdated = Boolean(
        currentVersion && currentVersion !== version
      );
      setIsUpdateAvailable(isVersionOutdated);
    } catch (error) {
      console.error("Failed to check version:", error);
    }
  };

  const reloadPageToViewNewFeatures = () => {
    localStorage.setItem(APP_VERSION_LOCAL_STORAGE_NAME, appVersion);
    setIsUpdateAvailable(false);
    window.location.reload();
  };

  const saveAppVersionInLocalStorage = () => {
    if (!currentVersion || currentVersion !== appVersion) {
      // Save the new version
      return localStorage.setItem(APP_VERSION_LOCAL_STORAGE_NAME, appVersion);
    }
  };

  const checkForLatestAppVersion = () => {
    const events = ["click", "scroll", "keypress", "beforeunload"];
    handleCheckAppVersion();
    saveAppVersionInLocalStorage();

    events.forEach((event) => {
      window.addEventListener(event, () => saveAppVersionInLocalStorage());
    });
    const intervalId = setInterval(handleCheckAppVersion, 2 * 60 * 1000); // 2 minutes
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, saveAppVersionInLocalStorage)
      );
      clearInterval(intervalId);
    };
  };

  return {
    reloadPageToViewNewFeatures,
    isUpdateAvailable,
    checkForLatestAppVersion,
  };
};

export default useVersionChecker;
