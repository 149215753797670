import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_REGISTER_QRCODE } from "../../config/connString";

const getRegisteredQrCodeRanges = createAsyncThunk(
  "qr-code/get-registered-ranges",
  async () => {
    try {
      const api = useAxios();
      const response = await api.get(
        URL_REGISTER_QRCODE + "/registered-ranges"
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getRegisteredQrCodeRanges };
