import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_SUPPLY_ORDER } from "config/connString";

const cancelSupplyRequest = createAsyncThunk(
  "supply-orders/cancel-supply-order",
  async (data) => {
    try {
      const api = useAxios();
      const response = await api.delete(
        URL_SUPPLY_ORDER + "/" + "supply-request" + "/" + data.requestId,
        {
          data: {
            requestId: data.requestId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { cancelSupplyRequest };
