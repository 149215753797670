import { Button, Form, Input, Modal, Popconfirm, Select } from "antd";
import { requirementTypeOptions } from "../labtest/data";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToastNotification } from "utils/showToastNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import {
  createRequirementTemplate,
  deleteRequirementTemplate,
  updateRequirementTemplate,
} from "store";

const CreateUpdateLabtestRequirementModal = ({
  open,
  onCancel,
  isDataForUpdate,
  dataForUpdate,
  refetchData,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      templateName: dataForUpdate?.templateName,
      requirementDetails: dataForUpdate?.requirementDetails,
      requirementType: dataForUpdate?.requirementType,
    });
  }, [form, dataForUpdate]);

  useEffect(() => {
    if (isDataForUpdate) {
      LoadUpdateData();
    }
  }, [isDataForUpdate, LoadUpdateData]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    if (isDataForUpdate) {
      return updateLabRequirement(values);
    }
    return createLabRequirement(values);
  };

  // Create Lab Requirement
  const createLabRequirement = (values) => {
    if (
      createRequirementTemplate.pending().type ===
      "lab-requirements/create-requirement-template/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(createRequirementTemplate(values)).then((res) => {
      const isCreateSuccessful = res?.payload?.success;

      if (isCreateSuccessful) {
        refetchData();
        setIsLoading(false);
        handleCancel();
        showToastNotification(
          "success",
          "Created Data",
          "Successfully created labtest requirement."
        );
      }

      if (!isCreateSuccessful) {
        setIsLoading(false);
        showToastNotification(
          "error",
          "Failed to create labtest requirement",
          errorMessagesDescription(res.payload?.response.data.errorMessages)
        );
      }
    });
  };

  // Update Lab Requirement
  const updateLabRequirement = (values) => {
    if (
      updateRequirementTemplate.pending().type ===
      "lab-requirements/update-requirement-template/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(
      updateRequirementTemplate({ id: dataForUpdate?.id, ...values })
    ).then((res) => {
      const isUpdateSuccessful = res?.payload?.success;

      if (isUpdateSuccessful) {
        refetchData();
        setIsLoading(false);
        handleCancel();
        showToastNotification(
          "success",
          "Updated Data",
          "Successfully updated labtest requirement."
        );
      }

      if (!isUpdateSuccessful) {
        setIsLoading(false);
        showToastNotification(
          "error",
          "Failed to update labtest requirement",
          errorMessagesDescription(res.payload?.response.data.errorMessages)
        );
      }
    });
  };

  // Delete Lab Requirement
  const deleteLabRequirement = () => {
    if (
      deleteRequirementTemplate.pending().type ===
      "lab-requirements/delete-requirement-template/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(deleteRequirementTemplate({ id: dataForUpdate?.id })).then(
      (res) => {
        const isDeleteSuccessful = res?.payload?.success;

        if (isDeleteSuccessful) {
          refetchData();
          setIsLoading(false);
          handleCancel();
          showToastNotification(
            "success",
            "Deleted Data",
            "Successfully deleted labtest requirement."
          );
        }

        if (!isDeleteSuccessful) {
          setIsLoading(false);
          showToastNotification(
            "error",
            "Failed to delete labtest requirement",
            errorMessagesDescription(res.payload?.response.data.errorMessages)
          );
        }
      }
    );
  };

  return (
    <Modal
      open={open}
      title={
        isDataForUpdate
          ? "Update Labtest Requirement"
          : "Create Labtest Requirement"
      }
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        className="mt-5"
        name="labtestRequirement"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        {/* Template Name */}
        <Form.Item
          label="Template Name"
          name="templateName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Template Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Template Name" />
        </Form.Item>
        {/* Requirement Details */}
        <Form.Item
          label="Requirement Details"
          name="requirementDetails"
          rules={[
            {
              required: true,
              message: <i>{"Please input Requirement Details."}</i>,
            },
          ]}
        >
          <Input placeholder="Requirement Details" />
        </Form.Item>
        {/* Requirement Type */}
        <Form.Item
          label="Requirement Type"
          name="requirementType"
          rules={[
            {
              required: true,
              message: <i>{"Please select Requirement Type."}</i>,
            },
          ]}
        >
          <Select
            virtual={false}
            options={requirementTypeOptions}
            placeholder="Requirement Type"
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {isDataForUpdate ? (
            <Popconfirm
              title="Delete the province"
              description="Are you sure to delete?"
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: isLoading, className: "submitBtn" }}
              onConfirm={deleteLabRequirement}
            >
              <Button loading={isLoading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateLabtestRequirementModal;
