import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTestsLookUp } from "store";
import { typeOptions } from "./data";
import useAdditionalInfoFunctions from "./useAdditionalInfoFunctions";
import decodedUserDetails from "utils/decodedUserDetails";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import notification from "components/jkt/global/openNotification";
import useTranslation from "hooks/useTranslation";
import InfoIcon from "@mui/icons-material/Info";
import { Alert } from "antd";
import { getLabRequirementRequireValue } from "components/jkt/testRequest/proxies/getLabRequirementRequireValue";

const EditAdditionalPatientInfoModal = ({
  handleCloseModal,
  selectedProfile,
  inputValues,
  setInputValues,
  profilePackagePanelLabRequirements,
}) => {
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const inputs = useSelector((state) => state.inputs);
  const [labTestNames, setLabTestNames] = useState([]);
  const dispatch = useDispatch();
  const { getTranslation, getGlobalTranslation } =
    useTranslation("RequestPage");
  const { handleInputChange, handleInputCheckboxChange } =
    useAdditionalInfoFunctions();
  const [groupsThatHasDirtyInputs, setGroupsThatHasDirtyInputs] = useState([]);

  const tooltipTitle = "Please fill atleast one data";

  useEffect(() => {
    //Fetching of labtest lookup
    dispatch(
      getTestsLookUp({
        clientId: decodedUserDetails()?.clientId,
        gender: inputs.gender,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.data;
      if (isFetchSuccessful) {
        setLabTestNames(res.payload.data);
      }
    });
  }, [dispatch, inputs.gender]);

  const handleCloseInputModal = () => {
    //For closing specimen and additional patient information modal
    handleCloseModal();
  };

  const getInvalidLabRequirementGroupNames = () => {
    const labRequirementGroupIdentifiers = [
      ...new Set(inputValues?.map((item) => item.groupIdentifier)),
    ].filter((data) => data !== null && data !== "");

    const checkedInputValues = labRequirementGroupIdentifiers.map(
      (groupIdentifier) => {
        const filteredInputValues = inputValues.filter(
          (item) => item.groupIdentifier === groupIdentifier
        );
        const filteredInputValuesLength = filteredInputValues.length;
        const isGroupFieldsInvalid =
          filteredInputValuesLength > 1 &&
          filteredInputValues.every(
            (item) =>
              !item.isRequired && (item.value === "" || item.value === null)
          );

        if (isGroupFieldsInvalid) {
          return groupIdentifier;
        }
      }
    );

    const filteredCheckedInputValues = checkedInputValues.filter(
      (item) => item && item
    );
    setGroupsThatHasDirtyInputs(filteredCheckedInputValues);

    if (filteredCheckedInputValues.length > 0) {
      return true;
    }
    return false;
  };

  const handleSave = (e) => {
    //Function for updating labtest requirements
    e.preventDefault();

    if (!getInvalidLabRequirementGroupNames()) {
      const updatedTestOrderDetails = multiStepFormInputs?.testOrderDetails.map(
        (detail) => {
          if (detail.testId === selectedProfile.id) {
            const inputIds = inputValues.map((data) => data.id);
            const savedDataKey =
              detail.patientTestRequirementDatas[0]?.patientRequirementDataDetails?.map(
                (req) => req.dataKey
              );
            const updatedPatientRequirementDataDetails =
              detail.patientTestRequirementDatas[0]?.patientRequirementDataDetails
                .filter((data) => inputIds.includes(data.dataKey))
                .map((patientInfoData) => {
                  const updatedValue = inputValues.find(
                    (inputData) => inputData.id === patientInfoData.dataKey
                  )?.value;

                  return {
                    ...patientInfoData,
                    dataValue: updatedValue,
                  };
                });

            const newlyAddedPatientRequirementDataDetails = inputValues
              .filter(
                (data) => !savedDataKey.includes(data.id) && data.value !== ""
              )
              .map(
                ({
                  id,
                  value,
                  description,
                  groupIdentifier,
                  requirementTemplateId,
                }) => ({
                  groupIdentifier,
                  requirementTemplateId,
                  patientTestRequirementDataId: id,
                  dataKey: id,
                  dataValue: value,
                  description: description,
                })
              );

            return {
              ...detail,
              patientTestRequirementDatas: [
                {
                  ...detail.patientTestRequirementDatas[0],
                  patientRequirementDataDetails:
                    updatedPatientRequirementDataDetails.concat(
                      newlyAddedPatientRequirementDataDetails
                    ),
                },
              ],
            };
          }
          return detail;
        }
      );

      const checkedUpdatedTestOrderDetails = updatedTestOrderDetails.map(
        (item) => ({
          ...item,
          patientTestRequirementDatas:
            item?.patientTestRequirementDatas?.length > 0
              ? item.patientTestRequirementDatas.map((reqData) => ({
                  ...reqData,
                  patientRequirementDataDetails:
                    reqData.patientRequirementDataDetails.map(
                      (reqDataDetail) => {
                        const newDataValue = inputValues.find(
                          (inputValue) =>
                            inputValue.requirementTemplateId ===
                            reqDataDetail.requirementTemplateId
                        )?.value;
                        return newDataValue
                          ? {
                              ...reqDataDetail,
                              dataValue: newDataValue,
                            }
                          : reqDataDetail;
                      }
                    ),
                }))
              : [],
        })
      );

      dispatch(
        handleChangeMultiFields({
          testOrderDetails: checkedUpdatedTestOrderDetails,
        })
      );
      notification.success({
        message: "Updated additional information",
        description: `Successfully updated ${selectedProfile.name}`,
      });
      handleCloseInputModal();
      setGroupsThatHasDirtyInputs([]);
    }
  };

  const showTestNameUniquely = (labtestInfoId, previousLabtestId) => {
    if (labtestInfoId !== previousLabtestId) {
      return true;
    }

    return false;
  };

  const showGroupNameUniquely = (currentGroupName, previousGroupName) => {
    //Function for showing labtest requirement group name
    if (currentGroupName !== previousGroupName) {
      return true;
    }

    return false;
  };

  return (
    <Box
      sx={{
        marginBottom: "2rem",
        maxHeight: "calc(100vh - 500px)",
        overflow: "auto",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          textAlign: "center",
          paddingTop: "0.5rem",
          color: "grayishBlue.light",
          fontWeight: "bold",
        }}
      >
        {getTranslation("Additional Patient Information")}
      </Typography>
      <Box
        sx={{
          backgroundColor: "#E7EBF4",
          padding: "1rem 0.7rem",
          margin: "0.8rem 1rem",
        }}
      >
        {selectedProfile.type === "Test"
          ? savedTestInputsInfo?.fetchedInputFields?.testDetails?.labTestRequirements?.map(
              (input, index) => {
                return (
                  <Box key={index}>
                    {showTestNameUniquely(
                      input.labTestInfoId,
                      savedTestInputsInfo?.fetchedInputFields?.testDetails
                        ?.labTestRequirements[
                        savedTestInputsInfo?.fetchedInputFields?.testDetails?.labTestRequirements?.indexOf(
                          input
                        ) - 1
                      ]?.labTestInfoId
                    ) && (
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                        }}
                      >
                        {getGlobalTranslation("For")} {selectedProfile.name}
                      </Typography>
                    )}
                    {(input.groupIdentifier === null ||
                      input.groupIdentifier === "") && (
                      <Divider sx={{ marginY: "12px" }} />
                    )}
                    {input.groupIdentifier !== null &&
                      input.groupIdentifier !== "" &&
                      showGroupNameUniquely(
                        input.groupIdentifier,
                        savedTestInputsInfo?.fetchedInputFields?.testDetails
                          ?.labTestRequirements[
                          savedTestInputsInfo?.fetchedInputFields?.testDetails?.labTestRequirements?.indexOf(
                            input
                          ) - 1
                        ]?.groupIdentifier
                      ) && (
                        <>
                          <Divider textAlign="center" sx={{ margin: "1rem 0" }}>
                            <Chip
                              label={
                                <div className="flex items-center gap-1">
                                  <p>{input?.groupIdentifier}</p>
                                  {getLabRequirementRequireValue(
                                    savedTestInputsInfo?.fetchedInputFields
                                      ?.testDetails?.labTestRequirements,
                                    input.groupIdentifier,
                                    input.isRequired
                                  )?.requireType === "OptionalAll" && (
                                    <Tooltip
                                      arrow
                                      title={tooltipTitle}
                                      placement="right"
                                    >
                                      <InfoIcon
                                        sx={{ color: "grayishBlue.light" }}
                                        fontSize="sm"
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              }
                            />
                          </Divider>
                          {groupsThatHasDirtyInputs.includes(
                            input.groupIdentifier
                          ) && (
                            <Alert
                              className="my-2"
                              message={
                                <p className="text-xs">
                                  Please make sure to fill in at least one of
                                  the data points.
                                </p>
                              }
                              type="error"
                              showIcon
                            />
                          )}
                        </>
                      )}
                    {input.requirementType === 3 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              onChange={(e) =>
                                handleInputCheckboxChange(
                                  e,
                                  index,
                                  setInputValues
                                )
                              }
                              checked={
                                inputValues.find(
                                  (_, inputIdx) => inputIdx === index
                                )?.value === "true"
                              }
                            />
                          }
                          id={index}
                          label={
                            <Typography
                              className={input.isRequired ? "required" : ""}
                              sx={{
                                color: "grayishBlue.light",
                                fontSize: "1rem",
                              }}
                              variant="p"
                            >
                              {input.requirementDetails}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    ) : input.requirementType === 4 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <FormControl>
                          <Typography
                            className={input.isRequired ? "required" : ""}
                            sx={{
                              color: "grayishBlue.light",
                              fontSize: "1rem",
                            }}
                            variant="p"
                          >
                            {input.requirementDetails}
                          </Typography>
                          <RadioGroup
                            row
                            value={
                              inputValues?.find(
                                (_, inputIdx) => inputIdx === index
                              )?.value || ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, index, setInputValues)
                            }
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio size="small" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio size="small" />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    ) : (
                      <Box sx={{ margin: "0.5rem 0" }}>
                        <Typography
                          className={input.isRequired ? "required" : ""}
                          sx={{
                            color: "grayishBlue.light",
                            fontSize: "1rem",
                          }}
                          variant="p"
                        >
                          {input.requirementDetails}
                        </Typography>
                        <TextField
                          id={index}
                          size="small"
                          value={
                            inputValues?.find(
                              (_, inputIdx) => inputIdx === index
                            )?.value || ""
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                border: "1px solid",
                                borderColor: "darkBlue.secondary",
                                borderRadius: "0.7rem",
                              },
                            },
                            marginTop: "0.5rem",
                            backgroundColor: "white",
                            width: "100%",
                            borderRadius: "0.7rem",
                          }}
                          required={input.isRequired}
                          type={
                            typeOptions.find(
                              (type) => type.id === input.requirementType
                            )?.type || "text"
                          }
                          onChange={(e) =>
                            handleInputChange(e, index, setInputValues)
                          }
                        />
                      </Box>
                    )}
                  </Box>
                );
              }
            )
          : savedTestInputsInfo.fetchedInputFields.length > 0 &&
            savedTestInputsInfo.fetchedInputFields?.map((input, index) => {
              return (
                <Box key={index}>
                  {showTestNameUniquely(
                    input.labTestInfoId,
                    savedTestInputsInfo.fetchedInputFields[
                      savedTestInputsInfo.fetchedInputFields.indexOf(input) - 1
                    ]?.labTestInfoId
                  ) && (
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      {getGlobalTranslation("For")}{" "}
                      {
                        labTestNames?.find(
                          (data) => data.id === input.labTestInfoId
                        )?.name
                      }
                    </Typography>
                  )}
                  {input.requirementType === 3 ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            onChange={(e) =>
                              handleInputCheckboxChange(
                                e,
                                index,
                                setInputValues
                              )
                            }
                            checked={
                              inputValues.find(
                                (_, inputIdx) => inputIdx === index
                              )?.value === "true"
                            }
                          />
                        }
                        id={index}
                        label={
                          <Typography
                            className={input.isRequired ? "required" : ""}
                            sx={{
                              color: "grayishBlue.light",
                              fontSize: "1rem",
                            }}
                            variant="p"
                          >
                            {input.requirementDetails}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ) : input.requirementType === 4 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <FormControl>
                        <Typography
                          className={input.isRequired ? "required" : ""}
                          sx={{
                            color: "grayishBlue.light",
                            fontSize: "1rem",
                          }}
                          variant="p"
                        >
                          {input.requirementDetails}
                        </Typography>
                        <RadioGroup
                          row
                          value={
                            inputValues?.find(
                              (_, inputIdx) => inputIdx === index
                            )?.value || ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, index, setInputValues)
                          }
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio size="small" />}
                            label={getGlobalTranslation("Yes")}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio size="small" />}
                            label={getGlobalTranslation("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box sx={{ margin: "0.5rem 0" }}>
                      <Typography
                        className={input.isRequired ? "required" : ""}
                        sx={{
                          color: "grayishBlue.light",
                          fontSize: "1rem",
                        }}
                        variant="p"
                      >
                        {input.requirementDetails}
                      </Typography>
                      <TextField
                        id={index}
                        size="small"
                        value={
                          inputValues?.find((_, inputIdx) => inputIdx === index)
                            ?.value || ""
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              border: "1px solid",
                              borderColor: "darkBlue.secondary",
                              borderRadius: "0.7rem",
                            },
                          },
                          marginTop: "0.5rem",
                          backgroundColor: "white",
                          width: "100%",
                          borderRadius: "0.7rem",
                        }}
                        required={input.isRequired}
                        type={
                          typeOptions.find(
                            (type) => type.id === input.requirementType
                          )?.type || "text"
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, setInputValues)
                        }
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.9rem",
          marginTop: "1rem",
          position: "absolute",
          marginTop: "1rem",
          bottom: 6,
          left: 0,
          right: 0,
          marginX: "auto",
        }}
      >
        <button onClick={handleSave} className={styles["save_btn"]}>
          {getGlobalTranslation("SaveChanges")}
        </button>
        <button
          className={styles["cancel_btn"]}
          onClick={handleCloseInputModal}
        >
          {getGlobalTranslation("Cancel")}
        </button>
      </Box>
    </Box>
  );
};

export default EditAdditionalPatientInfoModal;
