import { Box } from "@mui/material";
import { Badge } from "antd";
import React from "react";

const errorMessagesDescription = (errorMessages) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {errorMessages?.map((errorMessage) => (
        <Badge
          className="w-fit"
          key={errorMessage}
          status="error"
          text={errorMessage}
        />
      ))}
    </Box>
  );
};

export default errorMessagesDescription;
