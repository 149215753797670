import React from "react";
import { Alert, Button, Space } from "antd";
import { InfoCircleFilled, ReloadOutlined } from "@ant-design/icons";
import useVersionChecker from "hooks/useVersionChecker";

const AlertMessage = () => {
  const { reloadPageToViewNewFeatures } = useVersionChecker();

  return (
    <div className="absolute top-[15%] left-[55%] transform -translate-x-1/2 -translate-y-1/2 z-[99999999]">
      <Alert
        description={
          <Space direction="vertical">
            <p className="text-lg">
              <InfoCircleFilled className="mr-[5px] text-[#faad14]" /> New
              Version Available
            </p>
            <p className="text-md">
              The system has been updated. To ensure you're viewing the latest
              information, please refresh the page. Clicking 'Refresh' will
              reload the page automatically.
            </p>
            <div className="flex justify-end">
              <Button
                size="small"
                type="primary"
                onClick={reloadPageToViewNewFeatures}
                icon={<ReloadOutlined />}
              >
                Refresh
              </Button>
            </div>
          </Space>
        }
        type="warning"
      />
    </div>
  );
};

export default AlertMessage;
