import { Modal } from "antd";
import React from "react";

const TestModal = ({ children, isOpen }) => {
  return (
    <Modal
      wrapClassName="dynamic-test-modal"
      style={{ position: "relative" }}
      width={450}
      closable={false}
      open={isOpen}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default TestModal;
