import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, refreshUser } from "store";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import decodedUserDetails from "utils/decodedUserDetails";
import MenuItemRoute from "./menuItemRoutes";
import Typography from "@mui/material/Typography";
import AdminMenuItemRoute from "./adminMenuItemRoutes";
import { Grid } from "@mui/material";
import eRequestLogo from "assests/erequest.png";
import notification from "./openNotification";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import useClearReduxStore from "hooks/useClearReduxStore";
import RegularMenuItem from "./regularMenuItem";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import { useEffect, useState } from "react";

const drawerWidth = 240;

function MenuBar(prop) {
  const { window, pageName } = prop;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { handleResetReduxStore } = useClearReduxStore();
  const isMenuOpen = Boolean(anchorEl);
  const isRoleHasClientAdmin =
    decodedUserDetails()?.role?.includes("ClientAdmin");
  const userNeedsToChangePassword =
    decodedUserDetails()?.ForcePasswordChange === "True";
  const { user } = useSelector((state) => state.auth);

  const clientDetails =
    localStorage.getItem("clInf") !== "undefined" &&
    JSON.parse(localStorage.getItem("clInf"));

  useEffect(() => {
    if (isUserLoggedIn()) {
      if (decodedUserDetails()?.ForcePasswordChange === "True") {
        navigate("/change-password");
      }
    }
  }, [dispatch, navigate]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleResetReduxStore();
    localStorage.clear();
    setAnchorEl(null);
    dispatch(logout());
    dispatch(refreshUser());
    navigate("/login");
    notification.success({
      message: "User",
      description: `Logout successful`,
    });
  };

  const handleChangePassword = () => {
    setAnchorEl(null);
    navigate("/change-password");
  };

  const handleClickRegisterNewUser = () => {
    navigate("/register-new-user");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
      {isRoleHasClientAdmin && (
        <MenuItem onClick={handleClickRegisterNewUser}>
          Register New User
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: {
            sm: !userNeedsToChangePassword
              ? `calc(100% - ${drawerWidth}px)`
              : "100%",
          },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography className="w-full">{prop.pageName}</Typography>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography
                sx={{ width: "max-content" }}
                component="h6"
                variant="h6"
                color="inherit"
              >
                {"Hi, "}
                {user?.displayName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" display="block" gutterBottom>
                {clientDetails?.clientName}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {!userNeedsToChangePassword && (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <div>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                <img className="w-50" src={eRequestLogo} alt="eRequest" />
              </Toolbar>
              <Divider />
              <List component="nav" className="MenuListCss">
                <>
                  <RegularMenuItem pageName={pageName} />
                  <MenuItemRoute />
                  <AdminMenuItemRoute />
                </>
              </List>
            </div>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <div>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                <img className="w-50" src={eRequestLogo} alt="eRequest" />
              </Toolbar>
              <Divider />
              <List component="nav" className="MenuListCss">
                <>
                  <RegularMenuItem pageName={pageName} />
                  <MenuItemRoute />
                  <AdminMenuItemRoute />
                </>
              </List>
            </div>
          </Drawer>
        </Box>
      )}
      {renderMenu}
    </>
  );
}

MenuBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MenuBar;
