import { Box } from "@mui/material";
import React from "react";

const QrTabs = ({
  isSelectedOptionToCheckQr,
  isSelectedOptionToRegisterQr,
  isLoading,
  handleSelectOption,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        paddingX: "1rem",
      }}
    >
      <h4
        onClick={() => handleSelectOption("registerQr")}
        className={`text-lg cursor-pointer px-4 py-1 rounded ${
          isSelectedOptionToRegisterQr && "bg-[#1976D2] text-white "
        } ${isLoading && "pointer-events-none"} duration-300 ease-in-out`}
      >
        REGISTER QR CODE
      </h4>
      <h4
        onClick={() => handleSelectOption("checkQr")}
        className={`text-lg cursor-pointer px-4 py-1 rounded ${
          isSelectedOptionToCheckQr && "bg-[#1976D2] text-white"
        } ${isLoading && "pointer-events-none"} duration-300 ease-in-out`}
      >
        CHECK QR CODE
      </h4>
    </Box>
  );
};

export default QrTabs;
