import { Box, Chip, Divider } from "@mui/material";
import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { getRequirementType } from "./data";
import PopConfirm from "components/jkt/global/popConfirm";
import { EditOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  getRequirementTemplateLookup,
  getRequirementTemplateById,
} from "store";
import { groupData } from "utils/groupData";
import CreateLabRequirementPopover from "./createLabRequirementPopover";

const { Panel } = Collapse;
const { Text } = Typography;

const AddLabtestRequirements = ({
  labTestReqArr,
  selectedReqId,
  handleRemoveSavedLabTestReq,
  handleSelectReqToEdit,
  handleDiscardChanges,
  handleSaveLabTestReq,
  handleSelectIfRequired,
  handleChangeReqDetails,
  handleChangeGroupIdentifier,
  handleChangeIsGroup,
  labTestReqInputs,
  handleSelectReqType,
  handleChangeRequirementTemplateId,
  setLabTestReqArr,
}) => {
  const dispatch = useDispatch();
  const [showGroupIdentifierInput, setShowGroupIdentifierInput] =
    useState(false);
  const [requirementTemplateOptions, setRequirementTemplateOptions] = useState(
    []
  );
  const [
    isRequirementTemplateLookupPending,
    setIsRequirementTemplateLookupPending,
  ] = useState(false);
  const [openCreateRequirementPopover, setOpenCreateRequirementPopover] =
    useState(false);

  const isLabTestReqFormDirty =
    !labTestReqInputs.requirementDetails ||
    (labTestReqInputs.isGroup && labTestReqInputs.groupIdentifier === null);
  const emptySelectedReqId = selectedReqId === null;
  const selectRequirementTypeBorder =
    !emptySelectedReqId && "1px solid #1677FF";

  const groupedLabTestReqData = groupData("groupIdentifier", labTestReqArr);
  const definedGroupIdentifierOption = [
    ...new Set(labTestReqArr?.map((item) => item.groupIdentifier)),
  ]
    .filter((data) => data !== null && data !== "")
    .map((option) => ({
      label: option,
      value: option,
    }));

  const onRemoveLabRequirement = (id) => {
    handleRemoveSavedLabTestReq(id);
    if (definedGroupIdentifierOption.length === 1) {
      handleChangeGroupIdentifier(null);
    }
  };

  const fetchRequirementTemplateLookup = () => {
    if (
      getRequirementTemplateLookup.pending().type ===
      "lab-requirements/get-requirement-templates-lookup/pending"
    ) {
      setIsRequirementTemplateLookupPending(true);
    }
    dispatch(getRequirementTemplateLookup()).then((res) => {
      if (res?.payload?.success) {
        setIsRequirementTemplateLookupPending(false);
        setRequirementTemplateOptions(
          res?.payload?.data?.map((item) => ({
            label: item?.templateName,
            value: item?.id,
          }))
        );
      }
    });
  };

  useEffect(() => {
    fetchRequirementTemplateLookup();
    // eslint-disable-next-line
  }, [dispatch]);

  const onRequirementTemplateChange = async (value) => {
    const response = await dispatch(getRequirementTemplateById({ id: value }));
    const responseData = response?.payload?.data;
    handleChangeReqDetails(responseData?.requirementDetails);
    handleSelectReqType(responseData?.requirementType);
    handleChangeRequirementTemplateId(responseData?.id);
  };

  const filteredRequirementTemplateOption = requirementTemplateOptions?.filter(
    (item) => {
      return !labTestReqArr
        ?.filter((item) => {
          if (selectedReqId !== null) {
            return (
              item.requirementTemplateId !==
              labTestReqInputs.requirementTemplateId
            );
          }
          return item;
        })
        .some((test) => item?.value === test?.requirementTemplateId);
    }
  );
  return (
    <Box>
      <Divider textAlign="center" sx={{ margin: "1rem 0" }}>
        <Chip label="Labtest Requirement" />
      </Divider>
      <div className="flex items-center gap-2 w-full">
        <Form.Item label="Requirement Template">
          <Select
            style={{
              borderRadius: "0.5rem",
            }}
            loading={isRequirementTemplateLookupPending}
            onChange={onRequirementTemplateChange}
            options={filteredRequirementTemplateOption}
            virtual={false}
            value={labTestReqInputs.requirementTemplateId}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Please select requirement template"
          />
        </Form.Item>
        <CreateLabRequirementPopover
          open={openCreateRequirementPopover}
          onOpenChange={setOpenCreateRequirementPopover}
          refetchData={fetchRequirementTemplateLookup}
        >
          <Button type="primary" htmlType="button" className="submitBtn mb-6">
            +
          </Button>
        </CreateLabRequirementPopover>
      </div>
      <Form.Item label="Required">
        <Switch
          onChange={handleSelectIfRequired}
          checked={labTestReqInputs.isRequired}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Form.Item label="Is Grouped">
        <Switch
          onChange={handleChangeIsGroup}
          checked={labTestReqInputs.isGroup}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      {labTestReqInputs.isGroup && (
        <Form.Item label="Group Identifier" required>
          <div className="flex items-center gap-1">
            <div className="w-full">
              {(definedGroupIdentifierOption.length <= 0 ||
                showGroupIdentifierInput) && (
                <Input
                  placeholder="Group Identifier"
                  style={{
                    border: selectRequirementTypeBorder,
                  }}
                  onChange={(e) => handleChangeGroupIdentifier(e.target.value)}
                  value={labTestReqInputs.groupIdentifier}
                />
              )}
              {definedGroupIdentifierOption.length > 0 &&
                !showGroupIdentifierInput && (
                  <Select
                    style={{
                      border: selectRequirementTypeBorder,
                      borderRadius: "0.5rem",
                    }}
                    value={labTestReqInputs.groupIdentifier}
                    onChange={handleChangeGroupIdentifier}
                    options={definedGroupIdentifierOption}
                  />
                )}
            </div>
            {definedGroupIdentifierOption.length > 0 && (
              <div>
                {!showGroupIdentifierInput && (
                  <Tooltip title="Create custom identifier">
                    <Button
                      onClick={() => setShowGroupIdentifierInput(true)}
                      type="primary"
                      htmlType="button"
                      className="submitBtn"
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                )}
                {showGroupIdentifierInput && (
                  <Tooltip title="Discard">
                    <Button
                      onClick={() => {
                        setShowGroupIdentifierInput(false);
                        handleChangeGroupIdentifier(null);
                      }}
                      type="primary"
                      htmlType="button"
                      danger
                      className="danger"
                      icon={<CloseOutlined />}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </Form.Item>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Button
            onClick={handleSaveLabTestReq}
            disabled={isLabTestReqFormDirty}
            type="primary"
            htmlType="button"
            className="submitBtn"
          >
            {!emptySelectedReqId ? "Save Changes" : "Add Requirement"}
          </Button>
          {!emptySelectedReqId && (
            <Button
              type="primary"
              htmlType="button"
              danger
              onClick={handleDiscardChanges}
            >
              Discard Changes
            </Button>
          )}
        </Box>
      </Box>
      {groupedLabTestReqData?.length > 0 &&
        groupedLabTestReqData?.map((item) => {
          return item?.groupName === "undefined" ||
            item?.groupName === "null" ||
            item?.groupName === "" ? (
            item?.data?.map((test, idx) => (
              <Collapse className="my-2" key={item?.groupName}>
                <Panel
                  key={idx}
                  header={`Requirement Details # ${
                    labTestReqArr.indexOf(test) + 1
                  }`}
                >
                  <Space style={{ marginRight: "3rem" }}>
                    <p>Requirement Details:</p>
                    <Text code>{test.requirementDetails}</Text>
                  </Space>
                  <br />
                  <Space wrap style={{ margin: "0.5rem 0" }}>
                    <p>Requirement Type:</p>
                    <Text code>
                      {getRequirementType(test.requirementType).requirementType}
                    </Text>
                  </Space>
                  {test.groupIdentifier && (
                    <>
                      <br />
                      <Space wrap style={{ margin: "0.5rem 0" }}>
                        <p>Group Identifier:</p>
                        <Text code>{test.groupIdentifier}</Text>
                      </Space>
                    </>
                  )}
                  <br />
                  <Space wrap>
                    <p>Grouped:</p>
                    <Text code>{test.isGroup ? "Yes" : "No"}</Text>
                  </Space>
                  <br />
                  <Space wrap>
                    <p>Required:</p>
                    <Text code>{test.isRequired ? "Yes" : "No"}</Text>
                  </Space>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      gap: "0.4rem",
                    }}
                  >
                    <Button
                      size="small"
                      style={{
                        marginLeft: 2,
                        backgroundColor: "#ed6c02",
                        color: "white",
                      }}
                      onClick={() => handleSelectReqToEdit(test)}
                    >
                      Update
                    </Button>
                    <PopConfirm
                      title="Remove requirement info"
                      description="Are you sure do you want to remove this requirement?"
                      onConfirm={() =>
                        onRemoveLabRequirement(test.idForReference)
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        danger
                        type="primary"
                        className="danger"
                        size="small"
                      >
                        Remove
                      </Button>
                    </PopConfirm>
                  </Box>
                </Panel>
              </Collapse>
            ))
          ) : (
            <Card
              title={
                <div className="flex items-center justify-between">
                  <p>{item?.groupName}</p>
                  <Tooltip title="Delete group">
                    <Popconfirm
                      onConfirm={() => {
                        const filteredSaveLabTestReqData =
                          labTestReqArr?.filter((test) =>
                            item?.data?.every(
                              (item) =>
                                item?.requirementTemplateId !==
                                test?.requirementTemplateId
                            )
                          );

                        setLabTestReqArr(filteredSaveLabTestReqData);
                      }}
                      title="Are you sure?"
                    >
                      <DeleteOutlined className="text-red-400 cursor-pointer" />
                    </Popconfirm>
                  </Tooltip>
                </div>
              }
              className="my-2"
              key={item?.groupName}
              size="small"
            >
              <Collapse>
                {item?.data?.map((test, idx) => (
                  <Panel key={idx} header={`Requirement Details # ${idx + 1}`}>
                    <Space style={{ marginRight: "3rem" }}>
                      <p>Requirement Details:</p>
                      <Text className="w-full" code>
                        {test.requirementDetails}
                      </Text>
                    </Space>
                    <br />
                    <Space wrap style={{ margin: "0.5rem 0" }}>
                      <p>Requirement Type:</p>
                      <Text className="w-fit" code>
                        {
                          getRequirementType(test.requirementType)
                            .requirementType
                        }
                      </Text>
                    </Space>
                    {test.groupIdentifier && (
                      <>
                        <br />
                        <Space wrap style={{ margin: "0.5rem 0" }}>
                          <p>Group Identifier:</p>
                          <Text code>{test.groupIdentifier}</Text>
                        </Space>
                      </>
                    )}
                    <br />
                    <Space wrap>
                      <p>Grouped:</p>
                      <Text code>{test.isGroup ? "Yes" : "No"}</Text>
                    </Space>
                    <br />
                    <Space wrap>
                      <p>Required:</p>
                      <Text code>{test.isRequired ? "Yes" : "No"}</Text>
                    </Space>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        gap: "0.4rem",
                      }}
                    >
                      <Button
                        size="small"
                        style={{
                          marginLeft: 2,
                          backgroundColor: "#ed6c02",
                          color: "white",
                        }}
                        onClick={() => handleSelectReqToEdit(test)}
                      >
                        Update
                      </Button>
                      <PopConfirm
                        title="Remove requirement info"
                        description="Are you sure do you want to remove this requirement?"
                        onConfirm={() =>
                          onRemoveLabRequirement(test.idForReference)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          danger
                          type="primary"
                          className="danger"
                          size="small"
                        >
                          Remove
                        </Button>
                      </PopConfirm>
                    </Box>
                  </Panel>
                ))}
              </Collapse>
            </Card>
          );
        })}
    </Box>
  );
};

export default AddLabtestRequirements;
