import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_REQUIREMENT_TEMPLATES } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const getRequirementTemplateLookup = createAsyncThunk(
  "lab-requirements/get-requirement-templates-lookup",
  async () => {
    try {
      const api = useAxios();

      const response = await api.get(URL_REQUIREMENT_TEMPLATES + "-lookup");
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { getRequirementTemplateLookup };
