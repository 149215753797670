import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_SUPPLY_ORDER } from "config/connString";
import useAxios from "utils/useAxios";

const getSupplyRequestById = createAsyncThunk(
  "supply-orders/get-supply-request-by-id",
  async (data) => {
    try {
      const api = useAxios();

      const response = await api.get(
        // eslint-disable-next-line no-useless-concat
        URL_SUPPLY_ORDER + "/supply-requests" + "/" + data.requestId,
        {
          requestId: data.requestId,
        }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getSupplyRequestById };
