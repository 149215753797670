import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const DateRangeFilter = ({ handleDateChange, selectedDates }) => {
  return (
    <RangePicker
      allowEmpty={[true, true]}
      className="w-full"
      onChange={(_, dateString) => {
        handleDateChange(dateString);
      }}
      needConfirm={false}
      value={selectedDates?.map((dateValue) => {
        const emptyDateValue = dateValue === "";
        const isDateValueNewlySelected = typeof dateValue === "string";
        const formattedDateValue = emptyDateValue ? null : dayjs(dateValue);
        const checkedDateValue = isDateValueNewlySelected
          ? formattedDateValue
          : dateValue;

        return checkedDateValue;
      })}
      disabledDate={(current) => current.isAfter(dayjs())}
    />
  );
};

export default DateRangeFilter;
